import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/installing-node-js",
  "date": "2013-06-06",
  "title": "INSTALLING NODE.JS",
  "author": "admin",
  "tags": ["development", "javascript", "node.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article we are discussing how to get Node.js working on OSX along with npm, the package manager for node."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`There are two ways of installing Node.js on Mac
Download package from nodejs.org or download to one click install ing of Node.js and NPM.`}</p>
    <h2>{`Install from source`}</h2>
    <p>{`To install from source you probably know what you are doing but this article might help.`}</p>
    <h3>{`Install Homebrew`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`ruby -e "$(curl -fsSL https://raw.github.com/mxcl/homebrew/go)"
`}</code></pre>
    <h2>{`Install Node.js via Homebrew`}</h2>
    <p>{`Once Homebrew is installed you can go ahead and install Node.js`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`brew install node
`}</code></pre>
    <p>{`Now create a file called server.js and paste the server code`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var http = require('http');
http.createServer(function (req, res) {
  res.writeHead(200, {'Content-Type': 'text/plain'});
  res.end('Hello World\\n');}).listen(3000, "127.0.0.1");
  console.log('Server running at http://127.0.0.1:3000/');
}
`}</code></pre>
    <p>{`Save the file and from the console run.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`node server.js
`}</code></pre>
    <p>{`Now you can visit `}<a parentName="p" {...{
        "href": "http://127.0.0.1:3000/"
      }}>{`http://127.0.0.1:3000/`}</a>{` with your favorite browser and you are up and running with server side JavaScript.
Further more you can read the Node.js documentation if you want to learn more about what Node.js is and what you can do with it.`}</p>
    <h2>{`Installing npm`}</h2>
    <p>{`Node.js provides low level, lightweight platform, so there are lot of modules created for Node. The  package manager for Node - npm will help you manage these.`}</p>
    <p>{`Even though the package manager was recently removed from Homebrew, you'll need to install it manually.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl https://npmjs.org/install.sh | sh
`}</code></pre>
    <p>{`If executing scripts from curl doesn't sound good for you, you can download the source and then install:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git clone http://github.com/isaacs/npm.git
cd npmsudo make install
`}</code></pre>
    <p>{`You will find out that everything worked fine, other than having to set NODE_PATH.
NODE_PATH tells Node.js where to look for modules. This means you can do things like `}<inlineCode parentName="p">{`var client = require 'http'`}</inlineCode>{`instead of specifying the full path. `}</p>
    <p>{`To make sure my shell knows where to find Node.js modules, I added `}<inlineCode parentName="p">{`export NODE_PATH="/usr/local/lib/node"`}</inlineCode>{` to my `}<inlineCode parentName="p">{`.bashrc file`}</inlineCode>{`. `}</p>
    <p>{`As some modules have executables you will also need to add /usr/local/share/npm/bin to your PATH. Here's how it might look `}<inlineCode parentName="p">{`export PATH="/usr/local/bin:/.../usr/local/share/npm/bin:$PATH"`}</inlineCode></p>
    <p>{`Make sure you reload your shell with `}<inlineCode parentName="p">{`source ~/.bashrc `}</inlineCode>{` so the changes are applied.`}</p>
    <h2>{`Installing modules`}</h2>
    <p>{`Now we are can install Node modules using npm. Let's start with `}<strong parentName="p">{`Express`}</strong>{`, which is a good place to start - a Node framework inspired by Sinatra. `}<inlineCode parentName="p">{`npm install express`}</inlineCode></p>
    <p>{`This provides a solid foundation to start developing with Node.js including Jade Node template - the Haml (HTML abstraction markup language)  inspired Node templating engine.
Now let's code!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      